import {
   Button,
   Flex,
   type IconProps,
   Text,
   type ToastId,
   useToast,
   type UseToastOptions,
} from '@chakra-ui/react';
import { type Route } from 'next';
import { useRef } from 'react';

import { Icon } from '@/components/Icon';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

const useThemedToast = () => {
   const toast = useToast();
   const toastIdRef = useRef<ToastId>('');

   return {
      isActive: toast.isActive,
      toastSuccess: (
         title: string,
         description?: string | null,
         options: UseToastOptions = {},
         cta?: {
            label: string;
            link?: Route;
         },
      ) => {
         toastIdRef.current = toast({
            title,
            description,
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
            ...options,
            render: () => {
               return (
                  <Flex
                     pos="relative"
                     maxW={{ base: '320px', lg: '360px' }}
                     minH="80px"
                     bg="alert.quanternary"
                     p={5}
                     mb={5}
                     borderRadius={12}
                     boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                     alignItems="center"
                     justifyContent="flex-start"
                     gap={3}
                  >
                     <Button
                        variant="unstyled"
                        pos="absolute"
                        right={1.5}
                        top={1.5}
                        width={6}
                        height={6}
                        minH="none"
                        onClick={() => {
                           if (toastIdRef.current) {
                              toast.close(toastIdRef.current);
                           }
                        }}
                     >
                        <Icon name="close" w={3} h={3} color="white" />
                     </Button>

                     <Icon
                        name="checkmark-circled"
                        boxSize={7}
                        color="accent.green"
                     />

                     <Flex alignItems="flex-start" flexDir="column">
                        <Text
                           color="#1F454E"
                           size="paragraphSmall"
                           fontWeight={600}
                        >
                           {title}
                        </Text>
                        {cta ? (
                           <>
                              {cta.link ? (
                                 <NextLinkButton
                                    mt={3}
                                    href={cta.link}
                                    size="xs"
                                 >
                                    {cta.label}
                                 </NextLinkButton>
                              ) : (
                                 <Button
                                    mt={3}
                                    size="xs"
                                    onClick={() => {
                                       if (toastIdRef.current) {
                                          toast.close(toastIdRef.current);
                                       }
                                    }}
                                 >
                                    {cta.label}
                                 </Button>
                              )}
                           </>
                        ) : null}
                     </Flex>
                  </Flex>
               );
            },
         });
      },

      toastError: (
         title: string,
         description?: string | null,
         options: UseToastOptions = {},
         cta?: {
            label: string;
            link?: Route;
         },
      ) => {
         toastIdRef.current = toast({
            title,
            description,
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
            ...options,
            render: () => {
               return (
                  <Flex
                     pos="relative"
                     maxW="320px"
                     minH="80px"
                     bg="alert.tertiary"
                     justifyContent="flex-start"
                     alignItems="center"
                     p={5}
                     mb={5}
                     gap={3}
                     borderRadius={12}
                     boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                  >
                     <Button
                        variant="unstyled"
                        pos="absolute"
                        right={1.5}
                        top={1.5}
                        width={6}
                        height={6}
                        minH="none"
                        onClick={() => {
                           if (toastIdRef.current) {
                              toast.close(toastIdRef.current);
                           }
                        }}
                     >
                        <Icon name="close" w={3} h={3} color="white" />
                     </Button>

                     <Icon
                        name="error-circled"
                        boxSize={7}
                        color="rgba(0,0,0,0.1)"
                     />

                     <Flex alignItems="flex-start" flexDir="column">
                        <Text
                           color="white"
                           size="paragraphSmall"
                           fontWeight={600}
                        >
                           {title}
                        </Text>
                        {cta ? (
                           <>
                              {cta.link ? (
                                 <NextLinkButton
                                    mt={3}
                                    href={cta.link}
                                    size="xs"
                                    onClick={() => {
                                       if (toastIdRef.current) {
                                          toast.close(toastIdRef.current);
                                       }
                                    }}
                                 >
                                    {cta.label}
                                 </NextLinkButton>
                              ) : (
                                 <Button
                                    mt={3}
                                    size="xs"
                                    onClick={() => {
                                       if (toastIdRef.current) {
                                          toast.close(toastIdRef.current);
                                       }
                                    }}
                                 >
                                    {cta.label}
                                 </Button>
                              )}
                           </>
                        ) : null}
                     </Flex>
                  </Flex>
               );
            },
         });
      },

      toastWarning: (
         title: string,
         description?: string | null,
         options: UseToastOptions = {},
         cta?: {
            label: string;
            link?: Route;
         },
      ) => {
         toastIdRef.current = toast({
            title,
            description,
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
            ...options,
            render: () => {
               return (
                  <Flex
                     pos="relative"
                     maxW="320px"
                     minH="80px"
                     bg="alert.primary"
                     justifyContent="flex-start"
                     alignItems="center"
                     p={5}
                     gap={3}
                     borderRadius={12}
                     boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                  >
                     <Button
                        variant="unstyled"
                        pos="absolute"
                        right={1.5}
                        top={1.5}
                        width={6}
                        height={6}
                        minH="none"
                        onClick={() => {
                           if (toastIdRef.current) {
                              toast.close(toastIdRef.current);
                           }
                        }}
                     >
                        <Icon name="close" w={3} h={3} color="white" />
                     </Button>

                     <Icon
                        name="warning-circled"
                        boxSize={7}
                        color="rgba(0,0,0,0.1)"
                     />

                     <Flex alignItems="flex-start" flexDir="column">
                        <Text
                           color="white"
                           size="paragraphSmall"
                           fontWeight={600}
                        >
                           {title}
                        </Text>
                        {cta ? (
                           <>
                              {cta.link ? (
                                 <NextLinkButton
                                    mt={3}
                                    href={cta.link}
                                    size="xs"
                                    onClick={() => {
                                       if (toastIdRef.current) {
                                          toast.close(toastIdRef.current);
                                       }
                                    }}
                                 >
                                    {cta.label}
                                 </NextLinkButton>
                              ) : (
                                 <Button
                                    mt={3}
                                    size="xs"
                                    onClick={() => {
                                       if (toastIdRef.current) {
                                          toast.close(toastIdRef.current);
                                       }
                                    }}
                                 >
                                    {cta.label}
                                 </Button>
                              )}
                           </>
                        ) : null}
                     </Flex>
                  </Flex>
               );
            },
         });
      },

      toastCustomIcon: (
         title: string,
         icon: (props: IconProps) => React.ReactNode,
         description?: string | null,
         options: UseToastOptions = {},
      ) => {
         const Icon = icon;

         toast({
            title,
            description,
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
            ...options,
            render: () => {
               return (
                  <Flex
                     maxW="320px"
                     minH="84px"
                     bg="background.secondary"
                     justifyContent="flex-start"
                     alignItems="center"
                     p={5}
                     gap={3}
                     borderRadius={12}
                     boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                  >
                     <Icon width="44px" height="44px" />
                     <Text
                        color="background.fourth"
                        size="paragraphSmall"
                        fontWeight={600}
                     >
                        {title}
                     </Text>
                  </Flex>
               );
            },
         });
      },
   };
};

export default useThemedToast;
