import { Icon, type IconProps } from '@chakra-ui/react';

/**
 * Checkout journey
 */
export type StepStatus =
   | {
        status: 'done';
     }
   | {
        status: 'current';
        stepNumber: number;
     }
   | {
        status: 'disabled';
        stepNumber: number;
     };
export const ProgressIcon = (
   props: Omit<IconProps, 'fontSize'> &
      StepStatus & {
         fontSize?: 'normal' | 'small';
         variant?: 'light' | 'dark';
      },
) => {
   const { status, fontSize = 'normal', variant = 'light', ...rest } = props;

   switch (status) {
      case 'done':
         return <StepDoneIcon {...rest} />;
      case 'current':
         return (
            <StepCurrentIcon
               {...rest}
               variant={variant}
               stepNumber={props.stepNumber}
               fontSize={fontSize}
            />
         );
      case 'disabled':
         return <StepDisabledIcon {...rest} stepNumber={props.stepNumber} />;
   }
};
export const StepDoneIcon = (props: IconProps) => {
   return (
      <Icon
         w="24px"
         h="24px"
         viewBox="0 0 24 24"
         fill="#417777"
         stroke="white"
         {...props}
      >
         <circle cx="12" cy="12" r="12" stroke="none" />
         <path
            d="M16.1667 9.5L14.3333 11.3333L10.6667 15L9 13.3333L8.16667 12.5L7.75 12.0833"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </Icon>
   );
};

export const StepCurrentIcon = (
   props: Omit<IconProps, 'fontSize'> & {
      stepNumber: number;
      fontSize: 'normal' | 'small';
      variant?: 'light' | 'dark';
   },
) => {
   const {
      stepNumber,
      fontSize = 'normal',
      variant = 'light',
      ...rest
   } = props;
   return (
      <Icon w="24px" h="24px" viewBox="0 0 24 24" fill="none" {...rest}>
         <circle
            cx="12"
            cy="12"
            r="11.4"
            fill={variant === 'light' ? 'white' : '#417777'}
            stroke="#417777"
            strokeWidth="1.3"
         />
         <g
            transform={`translate(12, ${
               fontSize === 'normal' ? '16.5' : '15.5'
            })`}
            textAnchor="middle"
         >
            <text
               fill={variant === 'light' ? '#203C3C' : 'white'}
               fontSize={fontSize === 'normal' ? '14px' : '11px'}
               fontWeight="600"
            >
               {stepNumber}
            </text>
         </g>
      </Icon>
   );
};
export const StepDisabledIcon = (props: IconProps & { stepNumber: number }) => {
   const { stepNumber, ...rest } = props;
   return (
      <Icon w="24px" h="24px" viewBox="0 0 24 24" fill="#FFFFFF" {...rest}>
         <circle cx="12" cy="12" r="12" opacity="0.5" />

         <g transform="translate(12, 16.5)" textAnchor="middle">
            <text fill="white" fontSize="14px" fontWeight="600">
               {stepNumber}
            </text>
         </g>
      </Icon>
   );
};
